import { motion } from "framer-motion";
import { FaBuilding, FaCalendar, FaCheck } from "react-icons/fa";

interface Experience {
  company: string;
  position: string;
  period: string;
  description: string[];
  technologies: string[];
}

const experiences: Experience[] = [
  {
    company: "G-Global",
    position: "Desarrollador Web Full Stack",
    period: "Octubre 2022 - Abril 2023",
    description: [
      "Desarrollo de módulo de cruce de aduanas para importación y exportación de mercancías",
      "Implementación de validación de facturas .txt y sistema de alertas de errores",
      "Contribución al monorepo agregando nuevas funciones y soporte para múltiples microservicios",
    ],
    technologies: [
      "TypeScript",
      "Apollo GraphQL",
      "TanStack Query",
      "NestJS",
      "React.js",
      "MongoDB",
      "Mongoose",
      "Jest",
      "Amazon S3",
    ],
  },
  {
    company: "G-Global",
    position: "Desarrollador .NET Core Backend",
    period: "Mayo 2022 - Octubre 2022",
    description: [
      "Mantenimiento de servicios web en .NET Core",
      "Desarrollo de convertidor de factura electrónica XML (versiones 3.3 y 4.0) a formato .txt",
      "Implementación de servicios en la nube de AWS",
    ],
    technologies: [
      "C#",
      ".NET Core",
      "Java",
      "IIS",
      "SQL Server",
      "Amazon S3",
      "Amazon EC2",
      "JIRA",
      "Bitbucket",
    ],
  },
  {
    company: "Dynamic Network",
    position: "Desarrollador Web Full Stack Java",
    period: "Febrero 2020 - Mayo 2022",
    description: [
      "Desarrollo de sistema de homologación de claves de vehículos para compañías de seguros",
      "Implementación de módulo de registro de bitácora de ventas de seguros para Liverpool y Suburbia",
      "Actualización de servicio web SOAP a RESTful para GNP Insurance",
      "Desarrollo de sistema de promoción de seguros de automóviles",
    ],
    technologies: [
      "Java",
      "Spring Boot",
      "JSP",
      "Servlet",
      "MySQL",
      "HTML",
      "CSS",
      "JavaScript",
      "Bootstrap",
    ],
  },
];

export const ExperienceSection = () => {
  return (
    <section id="experience" className="py-20 bg-gray-50 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Experiencia Laboral
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            +3 años desarrollando soluciones empresariales
          </p>
        </motion.div>

        <div className="max-w-4xl mx-auto space-y-8">
          {experiences.map((exp, index) => (
            <motion.div
              key={`${exp.company}-${exp.position}`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="card hover-effect"
            >
              <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
                <div className="flex items-center space-x-4">
                  <div className="p-3 bg-indigo-100 dark:bg-indigo-900 rounded-xl group-hover:bg-indigo-200 dark:group-hover:bg-indigo-800 transition-colors duration-300">
                    <FaBuilding className="w-6 h-6 text-indigo-600 dark:text-indigo-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 dark:text-white group-hover:text-indigo-600 dark:group-hover:text-indigo-400 transition-colors duration-300">
                      {exp.position}
                    </h3>
                    <p className="text-indigo-600 dark:text-indigo-400 font-medium">
                      {exp.company}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-2 mt-4 md:mt-0 text-gray-500 dark:text-gray-400">
                  <FaCalendar className="w-4 h-4" />
                  <span>{exp.period}</span>
                </div>
              </div>

              <div className="space-y-4">
                <div className="prose prose-indigo dark:prose-invert max-w-none">
                  <ul className="space-y-2">
                    {exp.description.map((responsibility, idx) => (
                      <motion.li
                        key={idx}
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.1 * idx }}
                        className="flex items-start space-x-3 text-gray-600 dark:text-gray-300"
                      >
                        <FaCheck className="w-5 h-5 mt-1 text-green-500 dark:text-green-400 flex-shrink-0" />
                        <span>{responsibility}</span>
                      </motion.li>
                    ))}
                  </ul>
                </div>

                <div className="flex flex-wrap gap-2 pt-4">
                  {exp.technologies.map((tech, idx) => (
                    <motion.span
                      key={tech}
                      initial={{ opacity: 0, scale: 0.8 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ delay: 0.05 * idx }}
                      className="px-3 py-1 text-sm bg-gray-100 dark:bg-gray-700 
                               text-gray-800 dark:text-gray-300 rounded-full
                               hover:bg-indigo-100 dark:hover:bg-indigo-900
                               hover:text-indigo-600 dark:hover:text-indigo-400
                               transition-colors duration-300"
                    >
                      {tech}
                    </motion.span>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};
