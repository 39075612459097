import { motion } from "framer-motion";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";

interface Project {
  title: string;
  description: string;
  image: string;
  technologies: string[];
  githubUrl?: string;
  liveUrl?: string;
}

const projects: Project[] = [
  {
    title: "Carrito de compras",
    description:
      "Microservicios con Spring Boot, implementando patrones de resiliencia y service discovery.",
    image: "/images/projects/carrito.jpg",
    technologies: ["Spring Boot", "Hibernate", "JPA", "Resilience4J", "Eureka"],
    githubUrl: "https://github.com/danielhd94/carrito-compras",
    liveUrl: "https://carrito-compras-demo.com",
  },
  {
    title: "Punto de venta",
    description:
      "Aplicación de escritorio para gestión de ventas utilizando Java y MySQL con arquitectura MVC.",
    image: "/images/projects/pos.jpg",
    technologies: ["Java", "MySQL", "MVC", "Swing"],
    githubUrl: "https://github.com/danielhd94/punto-venta",
  },
  {
    title: "Sistema de control escolar",
    description:
      "Sistema de gestión escolar desarrollado en C# con SQL Server para administración educativa.",
    image: "/images/projects/school.jpg",
    technologies: ["C#", "SQL Server", "MVC", "Windows Forms"],
    githubUrl: "https://github.com/danielhd94/control-escolar",
  },
];

export const ProjectsSection = () => {
  return (
    <section id="projects" className="py-20 bg-white dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Proyectos Destacados
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Una selección de mis trabajos más recientes
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <motion.div
              key={project.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="card group overflow-hidden"
            >
              <div className="relative aspect-video overflow-hidden">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
              </div>

              <div className="p-6 space-y-4">
                <h3 className="text-xl font-bold text-gray-900 dark:text-white group-hover:text-indigo-600 dark:group-hover:text-indigo-400 transition-colors">
                  {project.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">{project.description}</p>

                <div className="flex flex-wrap gap-2">
                  {project.technologies.map(tech => (
                    <span
                      key={tech}
                      className="px-3 py-1 text-sm bg-gray-100 dark:bg-gray-700 
                               text-gray-800 dark:text-gray-300 rounded-full
                               hover:bg-indigo-100 dark:hover:bg-indigo-900
                               hover:text-indigo-600 dark:hover:text-indigo-400
                               transition-colors duration-300"
                    >
                      {tech}
                    </span>
                  ))}
                </div>

                <div className="flex space-x-4 pt-4">
                  {project.githubUrl && (
                    <a
                      href={project.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-gray-600 dark:text-gray-400 
                               hover:text-indigo-600 dark:hover:text-indigo-400 
                               transition-colors duration-300"
                      aria-label={`Ver código de ${project.title} en GitHub`}
                    >
                      <FaGithub className="w-5 h-5 mr-2" />
                      <span>Código</span>
                    </a>
                  )}
                  {project.liveUrl && (
                    <a
                      href={project.liveUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-gray-600 dark:text-gray-400 
                               hover:text-indigo-600 dark:hover:text-indigo-400 
                               transition-colors duration-300"
                      aria-label={`Ver demo de ${project.title}`}
                    >
                      <FaExternalLinkAlt className="w-5 h-5 mr-2" />
                      <span>Demo</span>
                    </a>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};
