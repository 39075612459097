import { motion } from "framer-motion";
import { FaDownload, FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { FC } from "react";

export interface SocialLink {
  href: string;
  icon: typeof FaGithub;
  label: string;
}

export interface AnimationProps {
  initial: { opacity: number; y?: number; scale?: number };
  animate: { opacity: number; y?: number; scale?: number };
  transition?: { duration: number };
}

const socialLinks: SocialLink[] = [
  {
    href: "https://github.com/danielhd94",
    icon: FaGithub,
    label: "GitHub",
  },
  {
    href: "https://linkedin.com/in/danielhernandezdiaz",
    icon: FaLinkedin,
    label: "LinkedIn",
  },
];

const fadeInUp: AnimationProps = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

export const HeroSection: FC = () => {
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    contactSection?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section id="home" className="min-h-screen flex items-center bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4 py-32">
        <div className="max-w-4xl mx-auto text-center">
          <motion.div {...fadeInUp}>
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="mb-8"
            >
              <div className="w-32 h-32 mx-auto rounded-full overflow-hidden ring-4 ring-indigo-600 dark:ring-indigo-400">
                <img
                  src="/images/profile/daniel-profile.jpg"
                  alt="Daniel Hernández"
                  className="w-full h-full object-cover"
                />
              </div>
            </motion.div>

            <h1 className="text-5xl md:text-6xl font-bold text-gray-900 dark:text-white mb-6">
              Daniel Hernández Díaz
            </h1>
            <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-300 mb-8">
              Desarrollador Full Stack | Java | Spring Boot | .NET Core | MERN
            </p>

            <div className="flex flex-wrap justify-center gap-6 mb-12">
              <motion.button
                onClick={scrollToContact}
                className="button-primary"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaEnvelope className="w-5 h-5 mr-2" />
                Contactar
              </motion.button>

              <motion.a
                href="/files/daniel-hernandez-cv.pdf"
                download
                className="button-secondary"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaDownload className="w-5 h-5 mr-2" />
                Descargar CV
              </motion.a>
            </div>

            <div className="flex justify-center space-x-6 mb-12">
              {socialLinks.map(({ href, icon: Icon, label }) => (
                <motion.a
                  key={label}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400 p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-all duration-300"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  aria-label={label}
                >
                  <Icon className="w-6 h-6" />
                </motion.a>
              ))}
            </div>

            <div className="mt-12">
              <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto leading-relaxed">
                Desarrollador Full Stack especializado en crear soluciones empresariales con Java
                Spring Boot, .NET Core y tecnologías web modernas.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
