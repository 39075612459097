import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  About,
  ContactSection,
  ExperienceSection,
  HeroSection,
  Layout,
  ProjectsSection,
} from "./components";
import OrdenDelDia from "./pages/OrdenDelDia";
import { GA_TRACKING_ID, initGA } from "./utils/analytics";

function App(): JSX.Element {
  useEffect(() => {
    initGA(GA_TRACKING_ID);
  }, []);

  return (
    <Router>
      <HelmetProvider>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  {/* SEO básico */}
                  <title>Daniel Hernández - Desarrollador Full Stack</title>
                  <meta
                    name="description"
                    content="Portafolio de Daniel Hernández, Desarrollador Full Stack especializado en React, Node.js y tecnologías web modernas."
                  />

                  {/* Open Graph / Facebook */}
                  <meta property="og:type" content="website" />
                  <meta property="og:url" content="https://www.danielhdez.com/" />
                  <meta property="og:title" content="Daniel Hernández - Desarrollador Full Stack" />
                  <meta
                    property="og:description"
                    content="Desarrollador Full Stack con experiencia en React, Node.js y tecnologías web modernas. Explora mi portafolio y proyectos."
                  />
                  <meta property="og:image" content="https://www.danielhdez.com/og-image.jpg" />
                  <meta property="og:image:width" content="1200" />
                  <meta property="og:image:height" content="630" />
                  <meta
                    property="og:image:alt"
                    content="Daniel Hernández - Desarrollador Full Stack"
                  />
                  <meta property="og:site_name" content="Daniel Hernández Portfolio" />

                  {/* Twitter */}
                  <meta name="twitter:card" content="summary_large_image" />
                  <meta name="twitter:url" content="https://www.danielhdez.com/" />
                  <meta
                    name="twitter:title"
                    content="Daniel Hernández - Desarrollador Full Stack"
                  />
                  <meta
                    name="twitter:description"
                    content="Desarrollador Full Stack con experiencia en React, Node.js y tecnologías web modernas. Explora mi portafolio y proyectos."
                  />
                  <meta name="twitter:image" content="https://www.danielhdez.com/og-image.jpg" />
                  <meta
                    name="twitter:image:alt"
                    content="Daniel Hernández - Desarrollador Full Stack"
                  />

                  {/* Favicon y Apple Touch Icons */}
                  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                </Helmet>
                <Layout>
                  <HeroSection />
                  <About />
                  <ExperienceSection />
                  <ProjectsSection />
                  <ContactSection />
                </Layout>
              </>
            }
          />
          <Route path="/ordendeldia" element={<OrdenDelDia />} />
        </Routes>
      </HelmetProvider>
    </Router>
  );
}

export default App;
