// Configuración de Google Analytics
export const GA_TRACKING_ID = "G-E8EGZJVXY7";

export const trackEvent = (category: string, action: string, label?: string) => {
  if (window.gtag) {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
    });
  }
};

export const initGA = (id: string): void => {
  if (typeof window !== "undefined") {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    document.head.appendChild(script);

    window.gtag = function (...args) {
      (window.dataLayer = window.dataLayer || []).push(args);
    };

    window.gtag("js", new Date());
    window.gtag("config", id, {
      page_path: window.location.pathname,
    });
  }
};
