import { motion } from "framer-motion";
import { FaArrowUp } from "react-icons/fa";

export const ScrollCTA = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <motion.button
      onClick={scrollToTop}
      className="fixed bottom-8 right-8 p-3 bg-indigo-600 text-white rounded-full shadow-lg hover:bg-indigo-700 transition-colors"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.1 }}
      aria-label="Volver arriba"
    >
      <FaArrowUp size={24} />
    </motion.button>
  );
};
