import { motion } from "framer-motion";
import { FaCode, FaLaptopCode, FaServer } from "react-icons/fa";

interface Skill {
  icon: typeof FaCode;
  title: string;
  description: string;
}

const skills: Skill[] = [
  {
    icon: FaCode,
    title: "Desarrollo Backend",
    description:
      "Especializado en Java Spring Boot y .NET Core para crear APIs robustas y escalables.",
  },
  {
    icon: FaLaptopCode,
    title: "Desarrollo Frontend",
    description:
      "Experiencia en React.js y TypeScript para crear interfaces modernas y responsivas.",
  },
  {
    icon: FaServer,
    title: "Arquitectura de Software",
    description: "Diseño de soluciones escalables utilizando microservicios y patrones de diseño.",
  },
];

export const About = () => {
  return (
    <section id="about" className="py-20 bg-gray-50 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Sobre Mí</h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Desarrollador Full Stack con más de 3 años de experiencia creando soluciones
            empresariales. Apasionado por las mejores prácticas y el aprendizaje continuo.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8">
          {skills.map((skill, index) => (
            <motion.div
              key={skill.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="bg-white dark:bg-gray-700 p-8 rounded-2xl shadow-lg"
            >
              <div className="flex flex-col items-center text-center">
                <div className="p-4 bg-indigo-100 dark:bg-indigo-900 rounded-full mb-6">
                  <skill.icon className="w-8 h-8 text-indigo-600 dark:text-indigo-400" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  {skill.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">{skill.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};
