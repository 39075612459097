import { useState } from "react";
import { motion } from "framer-motion";
import { FaWhatsapp, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { WhatsAppButtons } from "./WhatsAppButtons";

interface FormState {
  name: string;
  email: string;
  phone: string;
  message: string;
  loading: boolean;
}

export const ContactSection = () => {
  const [formState, setFormState] = useState<FormState>({
    name: "",
    email: "",
    phone: "",
    message: "",
    loading: false,
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormState(prev => ({ ...prev, loading: true }));

    // Construir el mensaje para WhatsApp con mejor formato
    const whatsappMessage = `
¡Hola Daniel! 👋

Me interesa explorar una colaboración para mi proyecto. Me presento:

*Información de contacto* 📋
━━━━━━━━━━━━━━━━
👤 *Nombre:* ${formState.name}
📧 *Email:* ${formState.email}
📱 *Teléfono:* ${formState.phone}

*Detalles del Proyecto* 💼
━━━━━━━━━━━━━━━━
${formState.message}

*Servicios de interés:*
• Desarrollo Web Full Stack
• Aplicaciones Empresariales Java/Spring
• Soluciones .NET Core
• Arquitectura de Software
• Optimización y Mantenimiento

_Mensaje enviado desde danielhdez.com_ 🚀
`.trim();

    // Número de WhatsApp
    const phoneNumber = "526631084697";

    // Crear el enlace de WhatsApp con el mensaje codificado
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;

    // Abrir WhatsApp en una nueva pestaña
    window.open(whatsappUrl, "_blank");

    // Resetear el formulario
    setFormState({
      name: "",
      email: "",
      phone: "",
      message: "",
      loading: false,
    });
  };

  return (
    <section id="contact" className="py-12 md:py-20 bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid md:grid-cols-2 gap-8 md:gap-12">
            {/* Información de contacto */}
            <div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="space-y-6 md:sticky md:top-24"
              >
                <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white">
                  Hablemos de tu proyecto
                </h2>
                <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
                  Usa los botones de mensaje rápido o completa el formulario para contactarme.
                </p>

                <WhatsAppButtons />

                <div className="mt-12 space-y-6">
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <div className="p-3 bg-green-100 dark:bg-green-900 rounded-full">
                        <FaWhatsapp className="w-6 h-6 text-green-600 dark:text-green-400" />
                      </div>
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                        WhatsApp / Teléfono
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">(+52) 663 108 4697</p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <div className="p-3 bg-indigo-100 dark:bg-indigo-900 rounded-full">
                        <FaEnvelope className="w-6 h-6 text-indigo-600 dark:text-indigo-400" />
                      </div>
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Email</h3>
                      <p className="text-gray-600 dark:text-gray-300">danielhd94@hotmail.com</p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <div className="p-3 bg-indigo-100 dark:bg-indigo-900 rounded-full">
                        <FaMapMarkerAlt className="w-6 h-6 text-indigo-600 dark:text-indigo-400" />
                      </div>
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                        Ubicación
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        Tijuana, Baja California, México
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Formulario - Mejorado para móviles */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 md:p-8"
            >
              <div className="text-center mb-12">
                <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
                  ¿Necesitas un desarrollador?
                </h2>
                <p className="text-xl text-gray-600 dark:text-gray-300">
                  Estoy disponible para proyectos freelance
                </p>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                  >
                    Nombre completo
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formState.name}
                    onChange={e => setFormState(prev => ({ ...prev, name: e.target.value }))}
                    className="w-full px-4 py-3 rounded-xl border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-green-500 dark:bg-gray-700 dark:text-white"
                    required
                  />
                </div>

                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={formState.email}
                      onChange={e => setFormState(prev => ({ ...prev, email: e.target.value }))}
                      className="w-full px-4 py-3 rounded-xl border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-green-500 dark:bg-gray-700 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                    >
                      Teléfono
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      value={formState.phone}
                      onChange={e => setFormState(prev => ({ ...prev, phone: e.target.value }))}
                      className="w-full px-4 py-3 rounded-xl border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-green-500 dark:bg-gray-700 dark:text-white"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                  >
                    Mensaje
                  </label>
                  <textarea
                    id="message"
                    rows={4}
                    value={formState.message}
                    onChange={e => setFormState(prev => ({ ...prev, message: e.target.value }))}
                    className="w-full px-4 py-3 rounded-xl border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-green-500 dark:bg-gray-700 dark:text-white resize-none"
                    required
                    placeholder="Por favor, describe tu proyecto incluyendo:
• Objetivo principal del proyecto
• Funcionalidades clave requeridas
• Plazo estimado de desarrollo
• Tecnologías preferidas (si las hay)
• Presupuesto aproximado (opcional)"
                  />
                </div>

                <button
                  type="submit"
                  disabled={formState.loading}
                  className="w-full px-6 py-3 md:px-8 md:py-4 bg-gradient-to-r from-green-500 to-emerald-600
                           text-white rounded-xl font-medium text-sm md:text-base
                           flex items-center justify-center space-x-2
                           hover:shadow-lg hover:shadow-green-500/25 
                           active:scale-98 transition-all duration-200
                           disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {formState.loading ? (
                    <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  ) : (
                    <div className="flex items-center space-x-2">
                      <FaWhatsapp className="w-5 h-5 mr-2" />
                      <span>Enviar por WhatsApp</span>
                    </div>
                  )}
                </button>

                <p className="text-sm text-gray-500 dark:text-gray-400 text-center mt-4">
                  Al enviar este formulario, serás redirigido a WhatsApp para continuar la
                  conversación
                </p>
              </form>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};
