import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";

const OrdenDelDia: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === "123") {
      setIsAuthenticated(true);
      setError("");
    } else {
      setError("Contraseña incorrecta");
      setPassword("");
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white p-8 rounded-2xl shadow-sm border border-gray-100 w-full max-w-md"
        >
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold text-gray-900 mb-2">¡Bienvenido!</h2>
            <p className="text-gray-600">
              Por favor, ingresa la contraseña para acceder a la orden del día
            </p>
          </div>
          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                Contraseña
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all shadow-sm"
                placeholder="Ingresa la contraseña"
                autoFocus
              />
            </div>
            {error && (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-red-500 text-sm text-center"
              >
                {error}
              </motion.p>
            )}
            <button
              type="submit"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-lg transition-colors shadow-sm flex items-center justify-center space-x-2"
            >
              <span>Acceder</span>
            </button>
          </form>
        </motion.div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Orden del Día - 9 de febrero 2025</title>
        <meta name="description" content="Orden del día para la reunión del 9 de febrero de 2025" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="max-w-4xl mx-auto px-4 py-20"
        >
          <div className="bg-white rounded-2xl shadow-sm p-8 border border-gray-100">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.1 }}
              className="text-center mb-16"
            >
              <h1 className="text-4xl font-bold text-gray-900 mb-2">Orden del Día</h1>
              <p className="text-lg text-gray-500">9 de febrero de 2025</p>
            </motion.div>

            <div className="space-y-8">
              {/* Tesorería */}
              <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="group hover:bg-gray-50 rounded-xl p-6 transition-all duration-200"
              >
                <h2 className="text-xl font-medium text-gray-900 flex items-center gap-3 mb-4">
                  <span className="text-2xl opacity-80">💰</span> 1. Tesorería
                </h2>
                <div className="space-y-4 ml-9">
                  <p className="text-sm font-medium text-gray-700">Aprobación de fondos:</p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="group-hover:text-gray-900 transition-colors">
                      • Evento Familiar del Hno. Wilmar (22 de junio)
                    </li>
                    <li className="group-hover:text-gray-900 transition-colors">
                      • Votación para liberación del presupuesto
                    </li>
                  </ul>
                </div>
              </motion.section>

              {/* Ministerio Infantil */}
              <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="group hover:bg-gray-50 rounded-xl p-6 transition-all duration-200"
              >
                <h2 className="text-xl font-medium text-gray-900 flex items-center gap-3 mb-4">
                  <span className="text-2xl opacity-80">👶</span> 2. Ministerio Infantil
                </h2>
                <div className="space-y-4 ml-9">
                  <p className="text-sm font-medium text-gray-700">Proyectos urgentes:</p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="group-hover:text-gray-900 transition-colors">
                      • Instalación de puertas en los departamentos
                    </li>
                    <li className="group-hover:text-gray-900 transition-colors">
                      • Selección de 2 nuevas maestras (votación)
                    </li>
                  </ul>
                </div>
              </motion.section>

              {/* Ministerio Familiar */}
              <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="group hover:bg-gray-50 rounded-xl p-6 transition-all duration-200"
              >
                <h2 className="text-xl font-medium text-gray-900 flex items-center gap-3 mb-4">
                  <span className="text-2xl opacity-80">👨👩👧👦</span> 3. Ministerio Familiar
                </h2>
                <div className="space-y-4 ml-9">
                  <p className="text-sm font-medium text-gray-700">Evento del 22 de junio:</p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="group-hover:text-gray-900 transition-colors">
                      • Logística y coordinación a cargo del ministerio
                    </li>
                    <li className="group-hover:text-gray-900 transition-colors">
                      • Necesidad de presupuesto (vinculado a Tesorería)
                    </li>
                  </ul>
                </div>
              </motion.section>

              {/* Ministerio Personal */}
              <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="group hover:bg-gray-50 rounded-xl p-6 transition-all duration-200"
              >
                <h2 className="text-xl font-medium text-gray-900 flex items-center gap-3 mb-4">
                  <span className="text-2xl opacity-80">🙋♂️</span> 4. Ministerio Personal
                </h2>
                <div className="space-y-4 ml-9">
                  <p className="text-sm font-medium text-gray-700">
                    Voto Bautismal: Discusión y aprobación
                  </p>
                  <p className="text-sm font-medium text-gray-700">Impacto Misionero:</p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="group-hover:text-gray-900 transition-colors">
                      • Presentación del pastor sobre venta de libros
                    </li>
                  </ul>
                </div>
              </motion.section>

              {/* Ancianos */}
              <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
                className="group hover:bg-gray-50 rounded-xl p-6 transition-all duration-200"
              >
                <h2 className="text-xl font-medium text-gray-900 flex items-center gap-3 mb-4">
                  <span className="text-2xl opacity-80">👥</span> 5. Ancianos
                </h2>
                <div className="space-y-4 ml-9">
                  <p className="text-sm font-medium text-gray-700">Puntos críticos:</p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="group-hover:text-gray-900 transition-colors">
                      • Voto de expulsión (procedimiento formal)
                    </li>
                    <li className="group-hover:text-gray-900 transition-colors">
                      • Nombramiento de nueva directora de diaconisas
                    </li>
                  </ul>
                </div>
              </motion.section>
            </div>

            {/* Perfil del Secretario */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              className="mt-16 pt-12 border-t border-gray-100"
            >
              <div className="text-center">
                <img
                  src="/images/profile/daniel-profile.jpg"
                  alt="Daniel Hernandez Diaz"
                  className="w-24 h-24 rounded-full mx-auto mb-4 shadow-sm border-2 border-gray-100 object-cover"
                />
                <h3 className="text-xl font-medium text-gray-900">Daniel Hernandez Diaz</h3>
                <p className="text-sm text-gray-500 mb-8">Secretario</p>

                <div className="space-y-1 text-gray-600 text-sm">
                  <p className="font-medium text-gray-900">IGLESIA ADVENTISTA DEL SÉPTIMO DÍA</p>
                  <p>UNIÓN MEXICANA DEL NORTE</p>
                  <p>ASOCIACIÓN BAJA CALIFORNIA</p>
                  <p className="font-medium">IGLESIA "MARANATHA III"</p>
                  <p>DISTRITO EL PIPILA</p>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default OrdenDelDia;
