interface WhatsAppButton {
  text: string;
  description: string;
  link: string;
}

const buttons: WhatsAppButton[] = [
  {
    text: "👋 Consulta Rápida",
    description: "Resuelve tus dudas en una breve charla",
    link: "https://wa.me/526631084697?text=Hola,%20me%20gustaría%20una%20consulta%20rápida",
  },
  {
    text: "🚀 Iniciar Proyecto",
    description: "Discutamos tu próximo proyecto",
    link: "https://wa.me/526631084697?text=Hola,%20me%20gustaría%20iniciar%20un%20proyecto",
  },
  {
    text: "🤝 Colaboración Pro",
    description: "Explora oportunidades de colaboración",
    link: "https://wa.me/526631084697?text=Hola,%20me%20interesa%20una%20colaboración%20profesional",
  },
];

export const WhatsAppButtons = () => {
  const phoneNumber = "526631084697";

  const handleWhatsAppClick = (message: string): void => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message.trim())}`;
    window.open(whatsappUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4">
      {buttons.map((msg, index) => (
        <button
          key={index}
          onClick={() => handleWhatsAppClick(msg.text)}
          className="flex items-center justify-center space-x-2 px-3 py-3 md:px-4 md:py-3 
                    bg-gradient-to-r from-green-500 to-green-600 
                    text-white rounded-xl font-medium text-sm md:text-base
                    hover:shadow-lg hover:shadow-green-500/25 
                    active:scale-95 hover:scale-105
                    transition-all duration-200"
        >
          <span className="whitespace-nowrap">{msg.text}</span>
        </button>
      ))}
    </div>
  );
};
